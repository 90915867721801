import Image, { StaticImageData } from 'next/image'
import { ElementType, FC, useMemo } from 'react'
import { HiChevronDoubleRight } from 'react-icons/hi'
import classnames from 'classnames'
import { useCollabsibleItem } from 'services/Collapsible'
import Styles from './TopicLabel.module.sass'

export type TopicLabelColor = 'default' | 'blue' | 'green' | 'gray'

interface TopicLabelProps {
  /**
   * Collapsible group id
   */
  groupId: string;

  /**
   * Collapsible item id
   */
  id: string;

  /**
   * Label text
   */
  text?: string;

  /**
   * Image to be displayed left
   */
  labelImage?: StaticImageData;

  /**
   * Color of the label
   */
  labelColor?: TopicLabelColor;

  /**
   * Wrapper for title to be rendered
   */
   labelAs?: ElementType;
}

const TopicLabel: FC<TopicLabelProps> = ({
  groupId,
  id,
  text,
  labelImage,
  labelColor = 'default',
  labelAs: LabelTag = 'div',
}) => {
  const { collapsed } = useCollabsibleItem(groupId, id)

  const wrapperClasses = useMemo(() => classnames({
    [Styles.wrapper]: true,
    [Styles.wrapperCollapsed]: collapsed,
    [Styles.wrapperBlue]: labelColor === 'blue',
    [Styles.wrapperGreen]: labelColor === 'green',
    [Styles.wrapperGray]: labelColor === 'gray',
  }), [collapsed, labelColor])

  return (
    <div className={wrapperClasses}>
      {labelImage && (
        <div className={Styles.imageWrapper}>
          <Image
            src={labelImage}
            alt={text}
            width={250}
            height={100}
            layout="fill"
            objectFit="cover"
            placeholder="blur"
          />
        </div>
      )}
      <LabelTag className={Styles.text}>
        {text}
      </LabelTag>
      <div className={Styles.icon}>
        <HiChevronDoubleRight />
      </div>
    </div>
  )
}

export default TopicLabel
