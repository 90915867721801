import 'styles/globals.sass'
import 'styles/icons.sass'
import type { AppProps } from 'next/app'
import PreviewWrapper from 'services/PreviewWrapper'
import PageContentMaintenance from 'pageContent/Maintenance'

const MAINTENANCE = process.env.MAINTENANCE === 'true'

function MyApp({ Component, pageProps }: AppProps) {
  return MAINTENANCE ? (
    <PageContentMaintenance />
  ) : (
    <PreviewWrapper>
      <Component {...pageProps} />
    </PreviewWrapper>
  )
}

export default MyApp
