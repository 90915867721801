import { FC, ReactNode, useMemo } from 'react'
import Link, { LinkProps } from 'next/link'
import classnames from 'classnames'
import { isPresent } from 'utils'
import Styles from './Card.module.sass'

interface CardProps {
  className?: string,
  href?: LinkProps['href'],
  padding?: boolean,
  children: ReactNode,
}

const Card: FC<CardProps> = ({
  className = '',
  href = null,
  padding = false,
  children,
}) => {
  const classes = useMemo(() => classnames({
    'card': true,
    [Styles.card]: true,
    [Styles.cardPadding]: padding,
    'card-link': isPresent(href),
    [Styles.cardLink]: isPresent(href),
    [className]: isPresent(className),
  }), [padding, href, className])

  return href && isPresent(href) ? (
    <Link href={href}>
      <a className={classes}>
        {children}
      </a>
    </Link>
  ) : (
    <div className={classes}>
      {children}
    </div>
  )
}

export default Card
